import { ShelfLifeEnum } from '../enums/shelf-life.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type ShelfLifeItem = IGenericListItem<ShelfLifeEnum>;

const shelfLifeItemCount: number = Object.keys(ShelfLifeEnum).length;

export const shelfLifeList: ShelfLifeItem[] = Object.keys(ShelfLifeEnum)
    .map((key: string, i: number) => ({
        id: i + 1,
        value: ShelfLifeEnum[key as keyof typeof ShelfLifeEnum],
        label: `SHELF_LIFE.${key}`,
    }))
    .concat([{ id: shelfLifeItemCount + 1, value: null, label: 'UTIL.NO_VALUE' }]);
