export enum ResultsAndReportsSortConditionsEnum {
    TASTING_ID = 'tasting.id',
    USER_ID = 'user.id',
    PRODUCT_ID = 'product.id',
    STA_YEAR = 'tasting.sta_year',
    CONTACT_OWNER = 'user.contact_owner',
    TEST_DATE = 'tasting.test_date',
    CATEGORY_ID = 'category.name',
    STARS = 'final_score.final_stars',
    SCORE = 'final_score.final_score',
    PRESTIGE_AWARD = 'tasting.prestige_award',
    PAYMENT_STATUS = 'tasting.payment_status',
    RESULTS_PUBLIC_CLIENT = 'tasting.result_public_to_client',
    PUBLISHED_ON_WEBSITE = 'tasting.published_on_website',
    REPORT_PUBLIC_CLIENT = 'tasting.report_public_to_client',
    LICENCE_AGREEMENT_VALIDATED = 'tasting.license_validated',
    FEEDBACK_ONLY = 'tasting.feedback_only',
    CREATED_AT = 'tasting.created_at'
}

export enum ResultsAndReportsFiltersTypeEnum {
    STA_YEAR = 'tasting.sta_year',
    CONTACT_OWNER = 'user.contact_owner',
    CATEGORY = 'product.category',
    CLAIMS = 'product.claims',
    STARS = 'final_score.stars',
    PRESTIGE_AWARD = 'tasting.prestige_award',
    PAYMENT_STATUS = 'user.payment_status',
    RESULTS_PUBLIC_CLIENT = 'tasting.result_public_to_client',
    PUBLISHED_ON_WEBSITE = 'tasting.published_on_website',
    REPORT_PUBLIC_CLIENT = 'tasting.report_public_to_client',
    LICENCE_AGREEMENT_VALIDATED = 'tasting.license_validated',
    OPTIONS = 'tasting.options',
    FEEDBACK_ONLY = 'tasting.feedback_only',
    OPTION_COUNT = 'tasting.options_count',
    COUNTRY = 'user.country',
    COMPANY_SIZE = 'user.company_size',
    CANDIDATE_AWARD = 'tasting.candidate_award',
    TEST_DATE = 'score.test_date',
    PACKSHOT = 'product.image',
    LOGO = 'user.logo',
    VIDEO_GENERATED = 'tasting.movie',
    REPORT_STATUS = 'report_management.report_status'
}

export enum ResultsAndReportsFiltersLabelEnum {
    STA_YEAR = 'PRE_TEST.STA_YEAR',
    CONTACT_OWNER = 'PRE_TEST.CONTACT_OWNER',
    FOOD_DRINK = 'PRE_TEST.FOOD_DRINK',
    MOTHER_CAT = 'PRE_TEST.MOTHER_CAT',
    CAT = 'PRE_TEST.CAT',
    CLAIMS = 'PRE_TEST.CLAIMS',
    STARS = 'RESULTS_AND_REPORTS.STARS',
    PRESTIGE_AWARD = 'RESULTS_AND_REPORTS.PRESTIGE_AWARD',
    PAYMENT_STATUS = 'PRE_TEST.PAYMENT_STATUS',
    RESULTS_PUBLIC_CLIENT = 'RESULTS_AND_REPORTS.RESULTS_PUBLIC_CLIENT',
    PUBLISHED_ON_WEBSITE = 'RESULTS_AND_REPORTS.PUBLISHED_ON_WEBSITE',
    REPORT_PUBLIC_CLIENT = 'RESULTS_AND_REPORTS.REPORT_PUBLIC_CLIENT',
    LICENCE_AGREEMENT_VALIDATED = 'RESULTS_AND_REPORTS.LICENCE_AGREEMENT_VALIDATED',
    OPTIONS = 'PRE_TEST.OPTIONS',
    FEEDBACK_ONLY = 'PRE_TEST.FEEDBACK_ONLY',
    OPTION_COUNT = 'PRE_TEST.OPTION_COUNT', // = TOTAL REPORTS
    COUNTRY = 'PRE_TEST.COUNTRY',
    COMPANY_SIZE = 'PRE_TEST.COMPANY_SIZE',
    CANDIDATE_AWARD = 'PRE_TEST.CANDIDATE_AWARD',
    TEST_DATE = 'RESULTS_AND_REPORTS.TEST_DATE',
    PACKSHOT = 'RESULTS_AND_REPORTS.PACKSHOT',
    LOGO = 'RESULTS_AND_REPORTS.LOGO',
    VIDEO_GENERATED = 'RESULTS_AND_REPORTS.VIDEO_GENERATED',
    REPORT_STATUS = 'RESULTS_AND_REPORTS.REPORT_STATUS',
}
